import React, { useState } from 'react';
import axios from 'axios';

const App = () => {
  const [file, setFile] = useState(null);
  const [summary, setSummary] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setError('Please select a file');
      return;
    }

    setIsLoading(true);
    setError('');
    const formData = new FormData();
    formData.append('file', file);

    try {
      // const response = await axios.post('http://localhost:8000/api/summary', formData, {
      const response = await axios.post('http://54.87.76.215:8000/api/summary', formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          },
          withCredentials: false
      });
      
      setSummary(response.data.summary);
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred while summarizing the file');
    } finally {
        setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-blue-600 text-white py-4">
        <div className="container mx-auto px-4">
          <h1 className="text-2xl font-bold">DOCUFLOW</h1>
        </div>
      </header>

      <main className="container mx-auto px-4 py-8">
        <h2 className="text-3xl font-semibold mb-6">Summarize Your Presentation</h2>
        <div className="bg-white shadow-md rounded-lg p-6">
          <form onSubmit={handleSubmit} className="mb-4">
            <div className="mb-4">
              <label htmlFor="file-upload" className="block text-sm font-medium text-gray-700">
                Upload PPTX file
              </label>
              <input
                id="file-upload"
                type="file"
                accept=".pptx"
                onChange={handleFileChange}
                className="mt-1 block w-full text-sm text-gray-500
                          file:mr-4 file:py-2 file:px-4
                          file:rounded-md file:border-0
                          file:text-sm file:font-semibold
                          file:bg-blue-50 file:text-blue-700
                          hover:file:bg-blue-100"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
              disabled={isLoading}
            >
              {isLoading ? 'Summarizing...' : 'Summarize'}
            </button>
          </form>
          {error && (
            <div className="mt-4 text-red-500">
              <p>{error}</p>
            </div>
          )}
          {isLoading && (
            <div className="mt-4">
              <p>Processing your presentation. This may take a few moments...</p>
            </div>
          )}
          {summary && (
            <div className="mt-6">
              <h3 className="text-xl font-semibold mb-2">Summary:</h3>
              <p className="text-gray-700">{summary}</p>
            </div>
          )}
        </div>
      </main>

      <footer className="bg-gray-200 py-4 mt-8">
        <div className="container mx-auto px-4">
          <p className="text-center text-gray-600">© 2024 DOCUFLOW. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default App;